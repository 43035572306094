import React from "react";
import Layout from "../components/Layout";
import HeaderSimple from "../components/HeaderSimple";
import ethic from "../images/ethic.svg";
import organization from "../images/organization.svg";

const ModelloOrganizzativoCodiceEtico = ({ pageContext }) => {
    return (
        <Layout pageContext={pageContext}>
            <HeaderSimple 
                title={pageContext.localeResources['ethic.title']} 
            />
            <section className="section-press ">
                <div className="container section">
                    <a 
                        className="card card-split-page rounded ie" 
                        href="https://it-pampanorama-dev.s3.eu-west-3.amazonaws.com/etc/regolamenti/modello-organizzativo-gruppo.pdf"
                        data-aos="fade-right" 
                        data-aos-offset="200"
                    >
                        <img alt="" src={organization} />
                        <h5 className="subtitle gray">{pageContext.localeResources['ethic.modelloOrganizzativo']}</h5>
                    </a>
                    <a 
                        className="card card-split-page rounded ie" 
                        href="https://it-pampanorama-dev.s3.eu-west-3.amazonaws.com/etc/regolamenti/codice-etico.pdf"
                        data-aos="fade-left" 
                        data-aos-offset="200"
                    >
                        <img alt="" src={ethic} />
                        <h5 className="subtitle gray">{pageContext.localeResources['ethic.codiceEtico']}</h5>
                    </a>
                </div>
            </section>
        </Layout>
    )
}

export default ModelloOrganizzativoCodiceEtico